<template>
  <footer>
    <div class="columns">
      <div class="first-column">
        <ul class="switcher">
          <li>
            <img alt="" src="@/assets/i/globe.svg" />
          </li>
          <li>
            <a
              href="#"
              @click.prevent="setLocale('en')"
              v-if="$i18n.locale !== 'en'"
              >EN</a
            ><span v-else style="cursor: not-allowed">EN</span>
          </li>
          <li>|</li>
          <li>
            <a
              href="#"
              @click.prevent="setLocale('ru')"
              v-if="$i18n.locale !== 'ru'"
              >RU</a
            ><span v-else class="disabled">RU</span>
          </li>
        </ul>
      </div>
      <div class="second-column">
        <div class="sections">
          <div class="nav">
            <ul>
              <li :key="index" v-for="(item, index) in items">
                <router-link
                  active-class="active"
                  :to="{
                    name: 'MovieList',
                    params: {
                      section: item.id,
                      collection: item.collections[0].id
                    }
                  }"
                  >{{ item.title }}</router-link
                >
              </li>
              <!-- <li>
                <router-link
                  :to="{ name: 'Favorites' }"
                  active-class="active"
                  >{{ $t("navigation.favorites") }}</router-link
                >
              </li> -->
              <!-- <li>
                <router-link
                  :to="{ name: 'Press' }"
                  active-class="active"
                  v-html="$t('navigation.press_footer')"
                ></router-link>
              </li> -->
              <!-- <li>
                <a href="/journal/index">{{
                  $t("navigation.journal_footer")
                }}</a>
              </li> -->
              <!-- <li>
                <router-link
                  to="/services"
                  active-class="active"
                  :class="{ disabled: !isFlight || !isAuth || !isService }"
                  >{{ $t("navigation.services_footer") }}</router-link
                >
              </li> -->
              <!-- <li>
                <router-link
                  v-if="isShopOn"
                  :to="{ name: 'Shop' }"
                  class="btn"
                  active-class="active"
                  ><span>{{ $t("navigation.shop_footer") }}</span></router-link
                >
                <span v-else class="not-available disabled">
                  {{ $t("navigation.shop_footer") }}
                </span>
              </li> -->
              <!-- <li>
                <router-link
                  :to="{ name: 'Menu' }"
                  active-class="active"
                  :class="{
                    'not-available disabled':
                      !isFlight || !isAuth || !isFoodMenu
                  }"
                  v-html="$t('navigation.food_menu_footer')"
                ></router-link>
              </li> -->

              <li>
              <router-link to="/airline" active-class="active"
                >{{ $t("pages.flight") }}
              </router-link>
            </li>
            <li>
              <router-link to="/tenYears" active-class="active"
                >{{ $t("navigation.tenYears") }}
              </router-link>
              <!-- <a class="not-available disabled">
                {{ $t("navigation.tenYears") }}
              </a> -->
            </li>
            </ul>
          </div>
          <div class="info">
            <div class="row">
              <div class="col col-24 col-m-14 col-s-12 col-links">
                <router-link class="icon license" to="/license" active-class="active">{{
                  $t("languages.license")
                }}</router-link>
                <router-link class="icon privacy" to="/privacy" active-class="active">{{
                  $t("languages.privacy")
                }}</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright">
          <p>
            © Copyright Premier IFE. Technics, 2024<br />
            © Content is copyrighted by respective content owners<br />
            © Entertainment program is copyrighted by AeroGroup llc.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import isFlight from "@/mixins/isFlight";
export default {
  name: "FooterCore",
  props: ["items"],
  mixins: [isFlight],
  methods: {
    ...mapMutations({
      setSize: "SET_SIZE"
    }),
    ...mapActions({
      setLocale: "SET_LOCALE"
    })
  }
};
</script>

<style scoped>
.not-available {
  color: #b4b5b6;
  text-shadow: "none";
}

footer
  .columns
  .second-column
  .sections
  .info
  .row
  .col.col-button
  .not-available-btn {
  color: #b4b5b6;
  text-shadow: "none";
  border-color: #b4b5b6;
}
</style>
