<template>
  <nav class="global" :class="{ hidden: !open }">
    <a href="" class="close" @click.prevent="$emit('close', true)"></a>
    <img
      v-if="$i18n.locale === 'ru'"
      src="~@/assets//img/logo-ru-blue.svg"
      alt=""
      class="logo logo-nav"
    />
    <img
      v-else
      src="~@/assets/img/logo-eng-blue.svg"
      alt=""
      class="logo logo-nav"
    />

    <div class="container">
      <div class="columns">
        <div class="left-col">
          <p class="header">
            <router-link
              :to="{ name: 'Index' }"
              class="header"
              :class="{ active: $route.fullPath === '/' }"
              >{{ $t("navigation.main") }}</router-link
            >
          </p>
          <p class="header">
            <router-link
              :to="{ name: 'Main' }"
              class="header"
              active-class="active"
              >{{ $t("navigation.entertainment") }}</router-link
            >
          </p>
          <ul>
            <li :key="index" v-for="(item, index) in items">
              <router-link
                active-class="selected"
                :to="{
                  name: 'MovieList',
                  params: {
                    section: item.id,
                    collection: item.collections[0].id
                  }
                }"
                >{{ item.title }}</router-link
              >
            </li>
            <!-- <li>
              <router-link
                :to="{ name: 'Favorites' }"
                active-class="selected"
                >{{ $t("navigation.favorites") }}</router-link
              >
            </li> -->
          </ul>

          <div class="legals">
            <p>
              &copy; Copyright Premier IFE. Technics, 2019 -
              {{ new Date().getUTCFullYear() }}
            </p>
            <p>&copy; Content is a copyright of respective content owners.</p>
            <p>&copy; Entertainment program is a copyright by AeroGroup llc.</p>
          </div>
          <!-- <p class="header">
            <router-link
              :to="{ name: 'Press' }"
              active-class="active"
              v-html="$t('navigation.press')"
            ></router-link>
          </p> -->
          <!-- <p class="header">
            <a href="/journal/index" v-html="$t('navigation.journal')"></a>
          </p> -->
          <!-- <p class="header">
            <router-link
              :to="{ name: 'Index' }"
              class=" header"
              :class="{
                selected: $route.fullPath === '/',
                'not-available disabled': isBox && !isService
              }"
              v-html="$t('navigation.services')"
            >
            </router-link>
          </p> -->
          <!-- <ul>
            <li></li>
            <li>
              <router-link
                :to="{ name: 'Services' }"
                active-class="selected"
                :class="{
                  'not-available disabled': isBox && (!isFlight || !isService)
                }"
                v-html="$t('navigation.services_header_nav')"
              >
              </router-link>
            </li>
            <li>
              <a
                class="not-available disabled"
                v-html="$t('navigation.seat_upgrade')"
              ></a>
            </li>
            <li>
              <router-link
                v-if="isShopOn"
                :to="{ name: 'Shop' }"
                active-class="selected"
                >{{ $t("navigation.shop_footer") }}</router-link
              >
              <span v-else class="not-available disabled">
                {{ $t("navigation.shop_footer") }}
              </span>
            </li>
            <li>
              <router-link
                :to="{ name: 'Menu' }"
                :class="{
                  'not-available disabled':
                    isBox && (!isFlight || !isAuth || !isFoodMenu)
                }"
                v-html="$t('navigation.food_menu')"
              ></router-link>
            </li>
          </ul> -->
        </div>

        <div class="right-col">
          <p class="header">
            <router-link
              to=""
              class="header"
              v-html="$t('navigation.information')"
            ></router-link>
          </p>
          <ul class="col-r-p">
            <!-- <li>
              <router-link
                :to="{ name: 'Crew' }"
                active-class="selected"
                :class="{
                  'not-available disabled': isBox && (!isFlight || !crewExist)
                }"
                v-html="$t('navigation.crew_header_nav')"
              >
              </router-link>
            </li> -->
            <li>
              <router-link to="/airline" active-class="selected"
                >{{ $t("pages.flight") }}
              </router-link>
            </li>
            <li>
              <router-link to="/tenYears" active-class="selected"
                >{{ $t("navigation.tenYears") }}
              </router-link>
            </li>
          </ul>
          <ul class="chooser">
            <li
              :class="{
                selected: $i18n.locale === 'ru',
                disabled: $i18n.locale === 'ru'
              }"
            >
              <a
                href="#"
                v-if="$i18n.locale !== 'ru'"
                @click.prevent="setLocale('ru')"
                ><img src="@/assets/i/flag-ru.png" alt="" />Русский</a
              >
              <span class="disabled" v-else
                ><img src="@/assets/i/flag-ru.png" alt="" />Русский</span
              >
            </li>
            <li
              :class="{
                selected: $i18n.locale === 'en',
                disabled: $i18n.locale === 'en'
              }"
            >
              <a
                href="#"
                v-if="$i18n.locale !== 'en'"
                @click.prevent="setLocale('en')"
                ><img src="@/assets/i/flag-en.png" alt="" />English</a
              >
              <span class="disabled" v-else
                ><img src="@/assets/i/flag-en.png" alt="" />English</span
              >
            </li>
          </ul>

          <!-- <div class="buttons">
            <router-link
              to="/captive"
              v-if="isFlight && !isAuth"
              class="btn auth"
              >{{ $t("navigation.authorization_header_nav") }}</router-link
            ><br />
            <router-link to="/help" class="btn help">{{
              $t("navigation.help")
            }}</router-link
            ><br />
            <span class="btn feedback disabled not-available-btn">{{
              $t("navigation.feedback")
            }}</span>
          </div> -->

          <div class="links">
            <router-link to="/privacy" class="privacy" active-class="selected">{{
              $t("languages.privacy")
            }}</router-link
            ><br />
            <router-link to="/license" class="license" active-class="selected">{{
              $t("languages.license")
            }}</router-link>
          </div>

          <div class="legals legals-mobile">
            <p>
              &copy; Copyright Premier IFE. Technics, 2019 -
              {{ new Date().getUTCFullYear() }}
            </p>
            <p>&copy; Content is a copyright of respective content owners.</p>
            <p>&copy; Entertainment program is a copyright by AeroGroup llc.</p>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import isFlight from "@/mixins/isFlight";

export default {
  name: "Navigation",
  props: ["open", "items"],
  mixins: [isFlight],
  watch: {
    open(value) {
      if (value) {
        document.querySelector("html").classList.add("navigation-opened");
      } else {
        document.querySelector("html").classList.remove("navigation-opened");
      }
    }
  },
  beforeDestroy() {
    document.querySelector("html").classList.remove("navigation-opened");
  },
  methods: {
    ...mapMutations({
      setSize: "SET_SIZE"
    }),
    ...mapActions({
      setLocale: "SET_LOCALE"
    })
  }
};
</script>

<style scoped>
nav.global .container .columns .buttons .not-available-btn {
  color: #b4b5b6;
  text-shadow: "none";
  border-color: #b4b5b6;
}
</style>
