var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"ground"},[_c('ul',{staticClass:"navigation"},[_c('li',{staticClass:"nav"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('openNavigation', true)}}},[_c('img',{attrs:{"alt":"","src":require("@/assets/img/burger.svg")}})])]),_c('li',{staticClass:"local-nav"},[(_vm.currentSection && !_vm.$route.params.section)?_c('router-link',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"},attrs:{"to":{
        name: 'MovieList',
        params: {
          section: _vm.currentSection.id,
          collection: _vm.currentSection.collections[0].id
        }
      }}},[_c('img',{attrs:{"src":require("@/assets/img/back-btn.svg"),"alt":""}}),_c('span',{staticStyle:{"overflow-y":"hidden"}},[_vm._v(_vm._s(_vm.currentSection.title))])]):(_vm.$route.params.section)?_c('router-link',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"},attrs:{"to":{ name: 'Main' }}},[_c('img',{attrs:{"src":require("@/assets/img/back-btn.svg"),"alt":""}}),_c('span',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"}},[_vm._v(_vm._s(_vm.$t("navigation.entertainment")))])]):(_vm.$router.currentRoute.name === 'Press')?_c('router-link',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"},attrs:{"to":{ name: 'Index' }}},[_c('img',{attrs:{"src":require("@/assets/img/back-btn.svg"),"alt":""}}),_c('span',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"}},[_vm._v(_vm._s(_vm.$t("navigation.press_crumbs"))+" ")])]):(_vm.$router.currentRoute.name === 'PressNumber')?_c('router-link',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"},attrs:{"to":{ name: 'Press' }}},[_c('img',{attrs:{"src":require("@/assets/img/back-btn.svg"),"alt":""}}),_c('span',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"}},[_vm._v(_vm._s(_vm.$t("navigation.press_crumbs"))+" ")])]):(_vm.$router.currentRoute.name === 'PressItem')?_c('router-link',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"},attrs:{"to":{
          name: 'PressNumber',
          params: { number: _vm.$route.params.number }
        }}},[_c('img',{attrs:{"src":require("@/assets/img/back-btn.svg"),"alt":""}}),_c('span',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"}},[_vm._v(_vm._s(_vm.$t("navigation.press_crumbs"))+" ")])]):(_vm.$router.currentRoute.name === 'ShopItem')?_c('router-link',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"},attrs:{"to":{
          name: 'ShopCategory',
          params: { category: _vm.$route.params.category }
        }}},[_c('img',{attrs:{"src":require("@/assets/img/back-btn.svg"),"alt":""}}),_c('span',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"},domProps:{"innerHTML":_vm._s(_vm.$t('navigation.shop_crumbs'))}})]):(_vm.$router.currentRoute.name === 'ShopCategory')?_c('router-link',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"},attrs:{"to":{
          name: 'Shop'
        }}},[_c('img',{attrs:{"src":require("@/assets/img/back-btn.svg"),"alt":""}}),_c('span',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"},domProps:{"innerHTML":_vm._s(_vm.$t('navigation.shop_crumbs'))}})]):(_vm.$router.currentRoute.name === 'ShopCard')?_c('router-link',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"},attrs:{"to":{
          name: 'Shop'
        }}},[_c('img',{attrs:{"src":require("@/assets/img/back-btn.svg"),"alt":""}}),_c('span',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"},domProps:{"innerHTML":_vm._s(_vm.$t('navigation.shop_crumbs'))}})]):(_vm.$router.currentRoute.name !== 'Index')?_c('router-link',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"},attrs:{"to":{ name: 'Index' }}},[_c('img',{attrs:{"src":require("@/assets/img/back-btn.svg"),"alt":""}}),_c('span',{staticStyle:{"overflow-y":"hidden","overflow-x":"hidden"}},[_vm._v(_vm._s(_vm.$t("navigation.home"))+" ")])]):_vm._e()],1),_c('li',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/"}},[(_vm.$i18n.locale === 'ru')?_c('picture',[_c('img',{attrs:{"src":require("@/assets/img/logo-ru-white.svg"),"alt":""}})]):_c('img',{attrs:{"src":require("@/assets/img/logo-eng-white.svg"),"alt":""}})])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }