var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"global",class:{ hidden: !_vm.open }},[_c('a',{staticClass:"close",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.$emit('close', true)}}}),(_vm.$i18n.locale === 'ru')?_c('img',{staticClass:"logo logo-nav",attrs:{"src":require("@/assets//img/logo-ru-blue.svg"),"alt":""}}):_c('img',{staticClass:"logo logo-nav",attrs:{"src":require("@/assets/img/logo-eng-blue.svg"),"alt":""}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"left-col"},[_c('p',{staticClass:"header"},[_c('router-link',{staticClass:"header",class:{ active: _vm.$route.fullPath === '/' },attrs:{"to":{ name: 'Index' }}},[_vm._v(_vm._s(_vm.$t("navigation.main")))])],1),_c('p',{staticClass:"header"},[_c('router-link',{staticClass:"header",attrs:{"to":{ name: 'Main' },"active-class":"active"}},[_vm._v(_vm._s(_vm.$t("navigation.entertainment")))])],1),_c('ul',_vm._l((_vm.items),function(item,index){return _c('li',{key:index},[_c('router-link',{attrs:{"active-class":"selected","to":{
                name: 'MovieList',
                params: {
                  section: item.id,
                  collection: item.collections[0].id
                }
              }}},[_vm._v(_vm._s(item.title))])],1)}),0),_c('div',{staticClass:"legals"},[_c('p',[_vm._v(" © Copyright Premier IFE. Technics, 2019 - "+_vm._s(new Date().getUTCFullYear())+" ")]),_c('p',[_vm._v("© Content is a copyright of respective content owners.")]),_c('p',[_vm._v("© Entertainment program is a copyright by AeroGroup llc.")])])]),_c('div',{staticClass:"right-col"},[_c('p',{staticClass:"header"},[_c('router-link',{staticClass:"header",attrs:{"to":""},domProps:{"innerHTML":_vm._s(_vm.$t('navigation.information'))}})],1),_c('ul',{staticClass:"col-r-p"},[_c('li',[_c('router-link',{attrs:{"to":"/airline","active-class":"selected"}},[_vm._v(_vm._s(_vm.$t("pages.flight"))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":"/tenYears","active-class":"selected"}},[_vm._v(_vm._s(_vm.$t("navigation.tenYears"))+" ")])],1)]),_c('ul',{staticClass:"chooser"},[_c('li',{class:{
              selected: _vm.$i18n.locale === 'ru',
              disabled: _vm.$i18n.locale === 'ru'
            }},[(_vm.$i18n.locale !== 'ru')?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setLocale('ru')}}},[_c('img',{attrs:{"src":require("@/assets/i/flag-ru.png"),"alt":""}}),_vm._v("Русский")]):_c('span',{staticClass:"disabled"},[_c('img',{attrs:{"src":require("@/assets/i/flag-ru.png"),"alt":""}}),_vm._v("Русский")])]),_c('li',{class:{
              selected: _vm.$i18n.locale === 'en',
              disabled: _vm.$i18n.locale === 'en'
            }},[(_vm.$i18n.locale !== 'en')?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setLocale('en')}}},[_c('img',{attrs:{"src":require("@/assets/i/flag-en.png"),"alt":""}}),_vm._v("English")]):_c('span',{staticClass:"disabled"},[_c('img',{attrs:{"src":require("@/assets/i/flag-en.png"),"alt":""}}),_vm._v("English")])])]),_c('div',{staticClass:"links"},[_c('router-link',{staticClass:"privacy",attrs:{"to":"/privacy","active-class":"selected"}},[_vm._v(_vm._s(_vm.$t("languages.privacy")))]),_c('br'),_c('router-link',{staticClass:"license",attrs:{"to":"/license","active-class":"selected"}},[_vm._v(_vm._s(_vm.$t("languages.license")))])],1),_c('div',{staticClass:"legals legals-mobile"},[_c('p',[_vm._v(" © Copyright Premier IFE. Technics, 2019 - "+_vm._s(new Date().getUTCFullYear())+" ")]),_c('p',[_vm._v("© Content is a copyright of respective content owners.")]),_c('p',[_vm._v("© Entertainment program is a copyright by AeroGroup llc.")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }