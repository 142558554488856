<template>
  <div class="modal modal-open" v-if="showModal && authenticate">
    <div class="modal-help">
      <div class="gradient"></div>
      <a href="" @click.prevent="closeModal" class="close"></a>

      <h4>{{ $t("services.titlePopup") }}</h4>
      <p>{{ $t("services.descriptionPopup") }}</p>
      <ul>
        <li
          v-for="(item, index) in layoutComp"
          :key="index"
          :class="{
            active: orders.find(order =>
              order.items.find(it => it.id === item.id)
            ),
            waiting: waiting.find(order => +order.id === +item.id)
          }"
        >
          <em></em>
          <a href="" @click.prevent="setOrderAction(item)">
            <figure
              v-bind:style="{
                'background-image':
                  'url(' + replaceCoverImage(item.imagePopup) + ')'
              }"
            ></figure>
            <p class="label">{{ item.titlePopup }}</p>
            <p
              class="cancel"
              :class="{ waiting: waiting.find(order => order.id === item.id) }"
              @click.prevent="
                cancelOrder({
                  id: item.id
                })
              "
            >
              {{ $t("services.cancel") }}
            </p>
          </a>
        </li>
      </ul>

      <p class="notice">Бортпроводник уже выполняет ваш заказ.</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import cover from "@/mixins/cover";

export default {
  name: "OrderHeader",
  mixins: [cover],
  computed: {
    ...mapState({
      orders: state => state.order.index,
      waiting: state => state.order.waiting,
      ordersLoading: state => state.order.indexLoading,
      layout: state => state.order.layout,
      layoutLoading: state => state.order.layoutLoading,
      showModal: state => state.order.showModal,
      authenticate: state => state.user.authenticate,
      isUpgrade: state => state.flight.isUpgrade
    }),
    layoutComp() {
      console.log(this.layout)
      if (!this.isUpgrade) {
        return this.layout.filter(item => item.category !== "upgradePlace");
      } else {
        return this.layout;
      }
    }
  },
  watch: {
    layoutLoading: {
      immediate: true,
      handler() {
        if (this.layoutLoading === "empty") {
          this.actionLayout();
        }
      }
    }
  },
  methods: {
    ...mapActions("order", {
      actionIndex: "index",
      actionLayout: "layout",
      setOrder: "setOrder",
      cancelOrder: "cancelOrder"
    }),
    ...mapMutations("order", {
      setEntity: "SET_ENTITY"
    }),
    closeModal() {
      this.setEntity(["showModal", false]);
    },
    setOrderAction(item) {
      if (item.category === "upgradePlace") {
        this.setEntity(["showModal", false]);
        if (this.$route.name !== "UpgradeSeat") {
          this.$router.push({ name: "UpgradeSeat" });
        }
      } else if (
        item.category === "service" &&
        !this.orders.find(order => order.items.find(it => it.id === item.id))
      ) {
        this.setOrder({
          id: +item.id
        });
      }
    }
  }
};
</script>

<style scoped></style>
